import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip
} from 'reactstrap';
import { AlertConfirm, Spinner, TableCustom } from '../../../components';
import moment from 'moment';
import 'moment/locale/fr';
import {
  loadEvents,
  removeEvent,
  saveEvent
} from '../../../helpers/actions/projects';
import { dateFormatter } from '../../../helpers/formatters';
import EventModal from './EventModal';
import SendEmail from '../SendEmails/SendEmail';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      events: [],
      eventEdit: null,
      sendByEmail: null,
      eventDelete: null
    };
  }

  componentWillMount() {
    let { events } = this.props;
    if (!events) this.load();
    else this.setState({ loading: false, ...this.props });
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    dispatch(loadEvents(projectId))
      .then(events => this.setState({ events, loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  showAdd() {
    this.setState({
      eventEdit: {
        address: this.props.projAddress,
        start: moment(),
        end: moment()
      }
    });
  }

  getEventsData() {
    let { events } = this.state,
      { t } = this.props;

    return events.map((event, key) => {
      return {
        className: '',
        data: [
          { className: '', value: event.title },
          { className: 'text-center', value: t(event.type) },
          {
            className: '',
            value: event.user ? event.user.displayName : ''
          },
          {
            className: '',
            fixed: true,
            value: dateFormatter(event.start)
          },
          {
            className: '',
            fixed: true,
            value: dateFormatter(event.end)
          },
          {
            className: 'text-center',
            fixed: false,
            value: event.send ? <i className="fa fa fa-check" /> : null
          },
          {
            className: '',
            properties: { width: '30%' },
            value: event.text
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Button
                key={`google-${key}`}
                id={'google_calendar' + event._id}
                className={'ml-1 btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.linkToGoogle(event)}
              >
                <i className="fa fa-calendar-plus-o icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`googleTooltip-${key}`}
                placement="left"
                target={'google_calendar' + event._id}
                delay={0}
              >
                <Trans>Add to Google calendar</Trans>
              </UncontrolledTooltip>,
              <Button
                key={`email-${key}`}
                id={'send_by_email' + event._id}
                className={'ml-1 btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.setState({ sendByEmail: event._id })}
              >
                <i className="fa fa-envelope icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`emailTooltip-${key}`}
                placement="left"
                target={'send_by_email' + event._id}
                delay={0}
              >
                <Trans>Send by email</Trans>
              </UncontrolledTooltip>,
              <Button
                key={`edit-${key}`}
                id={'edit' + event._id}
                className={'ml-1 btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.setState({ eventEdit: event })}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`editTooltip-${key}`}
                placement="left"
                target={'edit' + event._id}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>,
              <Button
                key={`delete-${key}`}
                id={'delete' + event._id}
                className={'ml-1 btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.setState({ eventDelete: event._id })}
              >
                <i className="fa fa-trash icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`deleteTooltip-${key}`}
                placement="left"
                target={'delete' + event._id}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            ]
          }
        ]
      };
    });
  }

  saveEvent(event) {
    this.setState({ loading: true });
    let { dispatch, projectId, projAddress } = this.props;

    if (!event.address) {
      event.address = projAddress;
    }

    delete event.user;
    dispatch(saveEvent(projectId, event))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  remove() {
    this.setState({ loading: true });
    let { eventDelete } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removeEvent(projectId, eventDelete))
      .then(() => this.setState({ eventDelete: null }, () => this.load()))
      .catch(() => this.setState({ eventDelete: null, loading: false }));
  }

  removeSendByEmail() {
    this.setState({ sendByEmail: null });
  }

  linkToGoogle(event) {
    let { projName, clientEmail } = this.props;
    let base = 'https://calendar.google.com/calendar/render?action=TEMPLATE';
    let start =
      '&dates=' + moment.utc(event.start).format('YYYYMMDDTHHmmss') + 'Z';
    let end = '/' + moment.utc(event.end).format('YYYYMMDDTHHmmss') + 'Z';
    let text = '&text=' + encodeURIComponent(projName + ' - ' + event.title);
    let busy = '&trp=true';
    let description =
      '&details=' + encodeURIComponent(event.text ? event.text : '');
    let location =
      '&location=' + encodeURIComponent(event.address ? event.address : '');
    let guests = '&add=' + clientEmail;
    let url =
      base + start + end + guests + text + description + location + busy;
    let win = window.open(url, '_blank');
    win.focus();
  }

  render() {
    let { loading, eventEdit, sendByEmail, eventDelete } = this.state,
      { t } = this.props;
    let rows = this.getEventsData();

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <Button size="sm" color={'info'} onClick={() => this.showAdd()}>
              <Trans>Add</Trans>
            </Button>
            <Button
              className={'ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>
          <CardBody>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[
                { value: t('Title') },
                { value: t('Type'), className: 'text-center' },
                { value: t('User') },
                { value: t('Start') },
                { value: t('End') },
                { value: t('Sent'), className: 'text-center' },
                { value: t('Description') },
                { value: '', className: 'text-right' }
              ]}
              rows={rows}
              emptyMessage={t('No events found')}
            />
          </CardBody>
        </Card>

        {!!eventEdit ? (
          <EventModal
            show={!!eventEdit}
            event={eventEdit}
            onCancel={() => this.setState({ eventEdit: null })}
            onConfirm={event =>
              this.setState({ eventEdit: null }, () => this.saveEvent(event))
            }
          />
        ) : null}

        {sendByEmail ? (
          <SendEmail
            hide={() => this.removeSendByEmail()}
            projectId={this.props.projectId}
            itemId={sendByEmail}
            itemType={'event'}
          />
        ) : null}

        {eventDelete ? (
          <AlertConfirm
            message={'The event cannot be recovered'}
            onCancel={() => this.setState({ eventDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Events));
