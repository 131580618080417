import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import Select from 'react-select';
import { all, remove, save } from '../../helpers/actions/packages';
import { all as allPieces } from '../../helpers/actions/pieces';
import { all as allSurfaces } from '../../helpers/actions/surfaces';
import { projectType } from '../../helpers/nomenclators';
import PackageCard from './PackageCard';
import PackageModal from './PackageModal';

class PackagesList extends Component {
  state = {
    loading: true,
    packs: [],
    count: 0,
    page: 1,
    limit: 18,
    surfaces: [],
    group: null,
    pieces: [],
    piece: null,
    search: '',
    type: null,
    pack: null,
    packDelete: null
  };

  componentDidMount() {
    this.setState({ loading: true });
    let { dispatch } = this.props;

    Promise.all([dispatch(allPieces({})), dispatch(allSurfaces({}))])
      .then(([pieces, surfaces]) => {
        this.setState({
          pieces: pieces.data,
          surfaces: surfaces.data
        });
        this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, piece, group, search, type } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, piece, group, search, type }))
      .then(({ data, meta }) =>
        this.setState({ packs: data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  onChangeTypeFilter(value) {
    this.setState({ loading: true });

    let { group, piece } = this.state,
      { dispatch } = this.props;
    let promises = [
      dispatch(allSurfaces({ type: value })),
      dispatch(allPieces({ type: value }))
    ];

    Promise.all(promises)
      .then(([groups, pieces]) => {
        let indexG =
          groups && groups.data
            ? groups.data.findIndex(t => t._id === group)
            : -1;
        group = indexG > -1 ? group : null;

        let indexP =
          pieces && pieces.data
            ? pieces.data.findIndex(t => t._id === piece)
            : -1;
        piece = indexP > -1 ? piece : null;

        this.setState(
          {
            page: 1,
            type: value,
            group,
            piece,
            surfaces: groups.data,
            pieces: pieces.data
          },
          () => this.load()
        );
      })
      .catch(() => this.setState({ loading: false }));
  }

  onSave(packEdit) {
    this.setState({ loading: true, pack: null });

    let { dispatch } = this.props;

    let promises = [dispatch(save(packEdit))];

    Promise.all(promises)
      .then(() => this.load())
      .catch(err => this.setState({ err, loading: false }));
  }

  deletePackage(id) {
    this.setState({ loading: true, packDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
        loading,
        page,
        limit,
        count,
        pieces,
        surfaces,
        packs,
        pack,
        packDelete
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              search: event.target.value
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={projectType.map(pt => ({
                          label: t(pt),
                          value: pt
                        }))}
                        value={this.state.type}
                        onChange={event =>
                          this.onChangeTypeFilter(event ? event.value : null)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Piece</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={pieces.map(p => ({
                          label: p.name,
                          value: p._id
                        }))}
                        value={this.state.piece}
                        onChange={event =>
                          this.setState(
                            { page: 1, piece: event ? event.value : null },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Surface</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={surfaces.map(p => ({
                          label: p.name,
                          value: p._id
                        }))}
                        value={this.state.group}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              group: event ? event.value : null
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <Button
                      className="float-right"
                      color="info"
                      onClick={() => this.setState({ pack: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {packs.length > 0 ? (
            <div>
              <Row>
                {packs.map((pack, key) => (
                  <Col key={key} xs={12} md={6} lg={4}>
                    <PackageCard
                      pack={pack}
                      onEdit={packEdit => this.setState({ pack: packEdit })}
                      onDelete={deletePackage =>
                        this.setState({ packDelete: deletePackage })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No packages found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!pack ? (
            <PackageModal
              dispatch={this.props.dispatch}
              show={!!pack}
              pack={pack}
              closeModal={() => this.setState({ pack: null })}
              savePackage={packEdit => this.onSave(packEdit)}
            />
          ) : null}

          {!packDelete ? null : (
            <AlertConfirm
              message={'The package cannot be recovered'}
              onCancel={() => this.setState({ packDelete: null })}
              onConfirm={() => this.deletePackage(packDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(PackagesList));
