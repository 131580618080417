import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import ReactQuill from 'react-quill';
import { quillConfig } from '../../../helpers/formatters';

class EstimationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: { text: '' }
    };
  }

  cleanModal() {
    this.setState({ report: { text: '' } });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onSaveReport() {
    let { report } = this.state;
    this.cleanModal();
    this.props.onConfirm(report);
  }

  render() {
    let { report } = this.state;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Create Project Estimation</Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup className={'has-label'}>
                <Label>
                  <Trans>Comments</Trans>
                </Label>
                <ReactQuill
                  value={report.text || ''}
                  modules={quillConfig}
                  className={'quill-text-editor'}
                  onChange={value =>
                    this.setState({
                      report: { ...report, text: value }
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveReport()}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(EstimationModal)
);
