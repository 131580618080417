import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Spinner } from '../../../components';
import get from 'lodash/get';
import { addPlan } from '../../../helpers/actions/projects';
import ReactQuill from 'react-quill';
import { quillConfig } from '../../../helpers/formatters';

class PlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: {},
      loading: false
    };
  }

  onChange(name, nameValid, value, valid) {
    let { plan } = this.state;

    this.setState({
      plan: { ...plan, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  handleClick() {
    let input = document.getElementById('uploadPlan');
    input.click();
  }

  handlePlanChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.onChange('file', 'fileValid', file, !!file);
  }

  validate() {
    let file = this.file;

    this.setState({
      [file.attributes.getNamedItem('namevalid').value]: file.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return file.validity.valid;
  }

  savePlan() {
    if (this.validate()) {
      this.setState({ loading: true });
      let { plan } = this.state,
        { dispatch, projectId } = this.props;

      dispatch(addPlan(projectId, plan))
        .then(() => {
          this.cleanModal();
          this.props.onConfirm();
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  cleanModal() {
    this.setState({
      typeValid: null,
      fileValid: null,
      plan: {},
      loading: false
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  render() {
    let { plan, loading } = this.state;

    return (
      <Modal
        size="lg"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Upload Plan</Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.titleValid
                }
              >
                <Label>
                  <Trans>Name</Trans>
                </Label>
                <Input
                  type="text"
                  innerRef={node => (this.name = node)}
                  namevalid="nameValid"
                  value={plan.name || ''}
                  required="required"
                  onChange={event =>
                    this.onChange(
                      'name',
                      'nameValid',
                      event.target.value,
                      event.target.validity.valid
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.textValid
                }
              >
                <Label>
                  <Trans>Description</Trans>
                </Label>
                <ReactQuill
                  innerRef={node => (this.description = node)}
                  namevalid="descriptionValid"
                  value={plan.description || ''}
                  modules={quillConfig}
                  className={'quill-text-editor'}
                  onChange={value =>
                    this.onChange(
                      'description',
                      'descriptionValid',
                      value,
                      true
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.fileValid
                }
              >
                <Label>
                  <Trans>Plan</Trans> PDF
                </Label>
                <Input
                  type="text"
                  innerRef={node => (this.file = node)}
                  namevalid="fileValid"
                  value={get(plan.file, 'name', '')}
                  required="required"
                  enable={'false'}
                  onClick={() => this.handleClick()}
                />
                <Button
                  className="float-right"
                  size="sm"
                  color={'info'}
                  onClick={() => this.handleClick()}
                >
                  <Trans>Upload</Trans>
                </Button>
                <input
                  type="file"
                  id="uploadPlan"
                  name="uploadPlan"
                  accept="application/pdf"
                  hidden={true}
                  multiple={false}
                  onChange={event => this.handlePlanChange(event)}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.savePlan()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(PlanModal));
