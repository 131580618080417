import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Spinner } from '../../../components';
import { Link } from 'react-router-dom';
import { one } from '../../../helpers/actions/projects';
import AssignClient from '../Client/AssignClient';
import AssignProvider from '../Provider/AssignProvider';
import AssignSupervisor from '../Supervisor/AssignSupervisor';
import ProjectCard from '../ProjectCard';
import Maps from '../Map3D/Maps';

class DetailsCompound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: {},
      hTabs: 'children'
    };
  }

  getChildren() {
    let { project, dispatch } = this.props;

    if (project && project.children) {
      let promises = project.children.map(child => {
        return dispatch(
          one(child, {
            include: [
              'client',
              'provider',
              'supervisors',
              'devis',
              'devisBase'
            ],
            fields: [
              'name',
              'status',
              'type',
              'description',
              'created_at',
              'address',
              'surface',
              'client',
              'provider',
              'supervisors',
              'devis',
              'devisBase'
            ]
          })
        );
      });

      Promise.all(promises)
        .then(res => {
          this.setState({ project: { ...project, children: res } });
        })
        .catch(() => {
          this.setState({ project: { ...project, children: [] } });
        });
    } else {
      this.setState({ project: { ...project, children: [] } });
    }
  }

  componentWillMount() {
    this.getChildren();
  }

  componentWillReceiveProps() {
    this.getChildren();
  }

  render() {
    let { loading, project, hTabs } = this.state;

    return (
      <div>
        {loading ? <Spinner /> : null}
        <Card>
          <CardBody>
            <Row>
              <Col xs={12} md={6}>
                <Nav pills className="nav-pills-info project-pills">
                  <NavItem>
                    <NavLink
                      className={hTabs === 'children' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'children' })}
                    >
                      <Trans>Children Projects</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={hTabs === 'general' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'general' })}
                    >
                      <Trans>General Information</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={hTabs === 'maps' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'maps' })}
                    >
                      <Trans>Maps</Trans>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col className={'text-right'} xs={12} md={6}>
                <Link
                  to={`/projects/new?parent=${project._id}`}
                  className="btn btn-sm btn-info mr-2"
                >
                  <i className="now-ui-icons ui-1_simple-add" />{' '}
                  <Trans>Son</Trans>
                </Link>
                <Link
                  to={`/projects/${project._id}/compound/devis`}
                  className="btn btn-sm btn-info mr-2"
                >
                  <Trans>Devis</Trans>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <TabContent activeTab={hTabs}>
          <TabPane tabId="children">
            {project && project._id && project.children ? (
              <Row>
                {project.children.map((child, key) => {
                  return (
                    <Col key={key} xs={12} md={4}>
                      <ProjectCard project={child} />
                      {/*<ChildCard projectId={child} />*/}
                    </Col>
                  );
                })}
              </Row>
            ) : null}
          </TabPane>
          <TabPane tabId="general">
            {project && project._id && hTabs === 'general' ? (
              <Row>
                <Col xs={12} lg={4} className="flex-column mb-4">
                  <AssignClient
                    project={project}
                    reload={() => this.props.reload()}
                  />
                </Col>
                <Col xs={12} lg={4} className="flex-column mb-4">
                  <AssignProvider
                    project={project}
                    reload={() => this.props.reload()}
                  />
                </Col>
                <Col xs={12} lg={4} className="flex-column mb-4">
                  <AssignSupervisor
                    project={project}
                    reload={() => this.props.reload()}
                  />
                </Col>
              </Row>
            ) : null}
          </TabPane>
          <TabPane tabId="maps">
            {project && project._id && project.maps && hTabs === 'maps' ? (
              <Maps projectId={project._id} maps={project.maps} />
            ) : null}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(DetailsCompound));
