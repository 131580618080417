import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import get from 'lodash/get';

class PackageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  render() {
    let { selected } = this.state,
      { pack, t } = this.props;

    return (
      <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState({ selected: !selected })}
        >
          <Row>
            <Col xs={12} lg={9}>
              <h6>{pack.name}</h6>
              {t(get(pack, 'type', ''))}
            </Col>
            <Col xs={12} lg={3} className={'text-right pl-1 pr-1'}>
              <Button
                id={`edit-${pack._id}`}
                className={'btn-icon mt-0 mb-0'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(pack)}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`edit-${pack._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${pack._id}`}
                className={'btn-icon mt-0 mb-0'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(pack)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${pack._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={'pt-0 pb-0'}
          style={{ display: selected ? 'block' : 'none' }}
        >
          <hr />
          <p>
            <strong>
              <Trans>Description</Trans>
            </strong>
            {`: ${get(pack, 'description', '-')}`}
          </p>
          <hr />
          <p>
            <strong>
              <Trans>Surface</Trans>
            </strong>
            {`: ${get(pack, 'group.name', '-')}`}
          </p>
          <hr />
          <p>
            <strong>
              <Trans>Piece</Trans>
            </strong>
            {`: ${get(pack, 'piece.name', '')}`}
          </p>
          <hr />
          <p className={'mb-0'}>
            <strong>
              <Trans>Operations</Trans>
            </strong>
            {': '}
          </p>
          <ul>
            {pack.operations.map((op, key) => (
              <li key={key}>{op.name}</li>
            ))}
          </ul>
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(PackageCard));
