import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { pdf } from '../../../helpers/actions/projects';

class PreviewModal extends Component {
  state = {
    preview: null
  };

  componentWillMount() {
    let { project, devisId } = this.props;
    this.generatePreview(project, devisId);
  }

  generatePreview(project, devisId) {
    let { dispatch } = this.props;

    if (project && devisId) {
      dispatch(pdf(project._id, devisId))
        .then(params => {
          const url =
              params.url +
              '?apiKey=' +
              params.httpHeaders.Authorization.replace('Bearer ', ''),
            filename = 'Devis - ' + project.name.replace(' ', '_') + '.pdf';
          this.setState({
            preview: {
              _id: devisId,
              url,
              filename
            }
          });
        })
        .catch(() => {});
    }
  }

  closeModal() {
    this.setState({ preview: null }, () => this.props.onClose());
  }

  render() {
    let { preview } = this.state;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Devis Preview</Trans>
        </ModalHeader>
        <ModalBody>
          {preview ? (
            <iframe
              title={this.state.preview.filename}
              src={this.state.preview.url}
              className="pdf-holder"
            />
          ) : null}
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(PreviewModal));
