import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap';
import { PaginationCustom, Spinner } from '../../components';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import get from 'lodash/get';
import { all as allPieces } from '../../helpers/actions/pieces';
import { all as allSurfaces } from '../../helpers/actions/surfaces';
import { all as allMaterials } from '../../helpers/actions/materials';
import { moneyFormatter } from '../../helpers/formatters';
import { projectType, units } from '../../helpers/nomenclators';
import defaultImage from '../../assets/img/image_placeholder.jpg';

class OperationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      operation: {},
      groups: [],
      pieces: [],
      materials: [],
      count: 0,
      page: 1,
      limit: 5,
      search: '',
      openPanel: '1'
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch, operation } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    let promises = [dispatch(allMaterials({ limit, offset, search }))];

    if (operation && operation.type) {
      promises.push(dispatch(allPieces({ type: operation.type })));
      promises.push(dispatch(allSurfaces({ type: operation.type })));
    }

    Promise.all(promises)
      .then(([materials, pieces, groups]) => {
        if (operation.unit && !units.find(unit => unit === operation.unit)) {
          units.push(operation.unit);
        }

        this.setState({
          operation: operation ? operation : {},
          groups: groups && groups.data ? groups.data : [],
          pieces: pieces && pieces.data ? pieces.data : [],
          materials: materials.data,
          count: materials.meta.count,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  cleanModal() {
    this.setState({
      nameValid: null,
      typeValid: null,
      groupValid: null,
      piecesValid: null,
      descriptionValid: null,
      unitValid: null,
      unitPriceValid: null,
      operation: {},
      groups: [],
      pieces: [],
      materials: [],
      count: 0,
      page: 1,
      limit: 5,
      search: '',
      openPanel: '1'
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    this.setState(prevState => {
      return {
        ...prevState,
        operation: { ...prevState.operation, [name]: value },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      };
    });
  }

  onChangeType(value, valid) {
    let { operation } = this.state,
      { dispatch } = this.props;

    if (!valid) {
      this.setState(prevState => {
        return {
          ...prevState,
          operation: {
            ...prevState.operation,
            type: value,
            group: null,
            pieces: []
          },
          typeValid: valid ? 'has-success' : 'has-danger',
          groupValid: null,
          piecesValid: null,
          groups: [],
          pieces: []
        };
      });
    } else if (operation.type !== value) {
      this.setState({ loading: true });

      Promise.all([
        dispatch(allPieces({ type: value })),
        dispatch(allSurfaces({ type: value }))
      ])
        .then(([pieces, groups]) => {
          this.setState(prevState => {
            return {
              ...prevState,
              operation: {
                ...prevState.operation,
                type: value,
                group: null,
                pieces: []
              },
              typeValid: valid ? 'has-success' : 'has-danger',
              groupValid: null,
              piecesValid: null,
              groups: groups.data,
              pieces: pieces.data,
              loading: false
            };
          });
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  onChangeMaterials(event, material) {
    let { operation } = this.state;
    operation.materials = operation.materials ? operation.materials : [];

    if (event.target.checked) {
      operation.materials.push({ ...material, cant: 1 });
    } else {
      const index = operation.materials.findIndex(m => m._id === material._id);
      if (index > -1) operation.materials.splice(index, 1);
    }

    this.setState({ operation });
  }

  getMaterials() {
    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(allMaterials({ limit, offset, search })).then(materials =>
      this.setState({
        materials: materials.data,
        count: materials.meta.count
      })
    );
  }

  validate() {
    let { operation } = this.state;
    let name = this.name;
    let description = this.description;
    let unit_price = this.unit_price;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      typeValid: operation.type ? 'has-success' : 'has-danger',
      groupValid: operation.group ? 'has-success' : 'has-danger',
      piecesValid:
        operation.pieces && operation.pieces.length
          ? 'has-success'
          : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      unitValid: operation.unit ? 'has-success' : 'has-danger',
      [unit_price.attributes.getNamedItem('namevalid').value]: unit_price
        .validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      operation.type &&
      description.validity.valid &&
      operation.unit &&
      unit_price.validity.valid &&
      operation.group &&
      operation.pieces &&
      operation.pieces.length
    );
  }

  onSaveOperation() {
    let { operation } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(operation);
    }
  }

  render() {
    let {
        loading,
        operation,
        groups,
        pieces,
        materials,
        page,
        count,
        limit
      } = this.state,
      { t } = this.props;

    return (
      <Modal
        size="lg"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {operation._id ? t('Edit Operation') : t('New Operation')}
        </ModalHeader>
        <ModalBody>
          {loading ? <Spinner /> : null}
          <div>
            <Nav pills className="nav-pills-info justify-content-center">
              <NavItem>
                <NavLink
                  className={this.state.openPanel === '1' ? 'active' : ''}
                  onClick={() => this.setState({ openPanel: '1' })}
                >
                  <Trans>General</Trans>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.openPanel === '2' ? 'active' : ''}
                  onClick={() => this.setState({ openPanel: '2' })}
                >
                  <Trans>Materials</Trans>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.openPanel}>
              <TabPane tabId="1">
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' + this.state.nameValid
                        }
                      >
                        <Label>
                          <Trans>Name</Trans>
                        </Label>
                        <Input
                          type="text"
                          innerRef={node => (this.name = node)}
                          namevalid="nameValid"
                          value={operation.name || ''}
                          required="required"
                          onChange={event =>
                            this.onChange(
                              'name',
                              'nameValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' + this.state.typeValid
                        }
                      >
                        <Label>
                          <Trans>Type</Trans>
                        </Label>
                        <Select
                          className="primary"
                          options={projectType.map(pt => ({
                            label: t(pt),
                            value: pt
                          }))}
                          value={operation.type}
                          onChange={event =>
                            this.onChangeType(
                              event ? event.value : null,
                              !!event
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.descriptionValid
                        }
                      >
                        <Label>
                          <Trans>Description</Trans>
                        </Label>
                        <Input
                          type="textarea"
                          innerRef={node => (this.description = node)}
                          namevalid="descriptionValid"
                          value={operation.description || ''}
                          onChange={event =>
                            this.onChange(
                              'description',
                              'descriptionValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.groupValid
                        }
                      >
                        <Label>
                          <Trans>Surface</Trans>
                        </Label>
                        <Select
                          className="primary"
                          options={groups.map(g => ({
                            label: g.name,
                            value: g.name
                          }))}
                          value={operation.group}
                          onChange={event =>
                            this.onChange(
                              'group',
                              'groupValid',
                              event ? event.value : null,
                              !!event
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.piecesValid
                        }
                      >
                        <Label>
                          <Trans>Pieces</Trans>
                        </Label>
                        <Select
                          className="primary"
                          multi={true}
                          closeOnSelect={false}
                          options={pieces.map(p => ({
                            label: p.name,
                            value: p.name
                          }))}
                          value={operation.pieces}
                          onChange={event =>
                            this.onChange(
                              'pieces',
                              'piecesValid',
                              event.map(e => e.value),
                              !!event.length
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.unitPriceValid
                        }
                      >
                        <Label>
                          <Trans>Unit Price</Trans>
                        </Label>
                        <Input
                          type="number"
                          innerRef={node => (this.unit_price = node)}
                          namevalid="unitPriceValid"
                          value={operation.unit_price || ''}
                          step={0.01}
                          min={1}
                          required="required"
                          onChange={event =>
                            this.onChange(
                              'unit_price',
                              'unitPriceValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' + this.state.unitValid
                        }
                      >
                        <Label>
                          <Trans>Unit</Trans>
                        </Label>
                        <CreatableSelect
                          namevalid="unitValid"
                          innerRef={node => (this.unit = node)}
                          className="primary"
                          closeOnSelect={true}
                          value={operation.unit}
                          options={units.map(p => ({ label: p, value: p }))}
                          onNewOptionClick={event => {
                            units.push(event.value);
                            this.onChange(
                              'unit',
                              'unitValid',
                              event ? event.value : null,
                              !!event
                            );
                          }}
                          onChange={event =>
                            this.onChange(
                              'unit',
                              'unitValid',
                              event ? event.value : null,
                              !!event
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col className={'text-left'} xs={4} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              search: event.target.value
                            },
                            () => this.getMaterials()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Table
                  responsive
                  striped
                  className={'devi-item-material-table'}
                >
                  <thead>
                    <tr>
                      <th className="text-center" />
                      <th>
                        <Trans>Image</Trans>
                      </th>
                      <th>
                        <Trans>Name</Trans>
                      </th>
                      <th className="text-right">
                        <Trans>Price</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {materials.map((material, key) => {
                      return (
                        <tr key={key}>
                          <td className="text-center">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    operation.materials &&
                                    operation.materials.findIndex(
                                      m => m._id === material._id
                                    ) > -1
                                  }
                                  onChange={event =>
                                    this.onChangeMaterials(event, material)
                                  }
                                />
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <div
                              className="piece-surface-image"
                              style={{
                                backgroundImage: `url('${get(
                                  material,
                                  'image',
                                  defaultImage
                                )}')`
                              }}
                            />
                          </td>
                          <td>{material.name}</td>
                          <td className="text-right">
                            {`${moneyFormatter(material.unit_price)} / ${get(
                              material,
                              'unit',
                              '-'
                            )}`}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Row>
                  <Col xs={12} className={'text-center'}>
                    <PaginationCustom
                      className="display-flex justify-content-center"
                      page={page}
                      count={count}
                      maxPerPage={limit}
                      onPageChange={page =>
                        this.setState({ page }, () => this.getMaterials())
                      }
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>
        <ModalFooter>
          {this.state.openPanel === '1'
            ? [
                <Button
                  key={'close'}
                  color="default"
                  className="text-left"
                  onClick={() => this.closeModal()}
                >
                  <Trans>Close</Trans>
                </Button>,
                <Button
                  key={'save'}
                  color="info"
                  className="text-right"
                  onClick={() => this.onSaveOperation()}
                >
                  <Trans>Save</Trans>
                </Button>
              ]
            : null}
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(OperationModal));
