import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import Select from 'react-select';
import { all, photo, remove, save } from '../../helpers/actions/pieces';
import { projectType } from '../../helpers/nomenclators';
import PieceCard from './PieceCard';
import PieceModal from './PieceModal';

class PiecesList extends Component {
  state = {
    loading: true,
    pieces: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    type: null,
    piece: null,
    pieceDelete: null
  };

  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search, type } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search, type }))
      .then(({ data, meta }) =>
        this.setState({ pieces: data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  onSave(pieceEdit, photoFile) {
    this.setState({ loading: true, piece: null });

    let { dispatch } = this.props;

    delete pieceEdit.photo;
    dispatch(save(pieceEdit))
      .then(pieceRes => {
        if (pieceRes._id && photoFile) {
          dispatch(photo(pieceRes._id, photoFile))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deletePiece(id) {
    this.setState({ loading: true, pieceDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
        loading,
        page,
        limit,
        count,
        pieces,
        piece,
        pieceDelete
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col className={'text-left'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              search: event.target.value
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={projectType.map(pt => ({
                          label: t(pt),
                          value: pt
                        }))}
                        value={this.state.type}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              type: event ? event.value : null
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={5}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ piece: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {pieces.length > 0 ? (
            <div>
              <Row>
                {pieces.map((piece, key) => (
                  <Col key={key} xs={12} md={4}>
                    <PieceCard
                      piece={piece}
                      onEdit={pieceEdit => this.setState({ piece: pieceEdit })}
                      onDelete={deletePiece =>
                        this.setState({ pieceDelete: deletePiece })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No pieces found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!piece ? (
            <PieceModal
              show={!!piece}
              piece={piece}
              closeModal={() => this.setState({ piece: null })}
              savePiece={(pieceEdit, photo) => this.onSave(pieceEdit, photo)}
            />
          ) : null}
          {!pieceDelete ? null : (
            <AlertConfirm
              message={'The piece cannot be recovered'}
              onCancel={() => this.setState({ pieceDelete: null })}
              onConfirm={() => this.deletePiece(pieceDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(PiecesList));
