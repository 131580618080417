import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip,
  Col,
  Row
} from 'reactstrap';
import { AlertConfirm, Spinner } from '../../../components';
import {
  loadPlans,
  removePlan,
  savePlan
} from '../../../helpers/actions/projects';
import PlanModal from './PlanModal';
import { Link } from 'react-router-dom';

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plans: [],
      projectId: null,
      uploadPlan: false,
      planDelete: null
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    dispatch(loadPlans(projectId, { type: JSON.stringify({ $in: ['Plans'] }) }))
      .then(plans => this.setState({ plans, loading: false, projectId }))
      .catch(() => this.setState({ loading: false }));
  }

  remove() {
    this.setState({ loading: true });
    let { planDelete } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removePlan(projectId, planDelete))
      .then(() => this.setState({ planDelete: null }, () => this.load()))
      .catch(() => this.setState({ planDelete: null, loading: false }));
  }

  validate(plan) {
    let valid = !plan.valide;
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;
    dispatch(savePlan(projectId, { _id: plan._id, valide: valid }))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  elementsByPage = (elements, page) => {
    let cant = {
      tasks: 0,
      notes: 0,
      images: 0
    };
    elements.forEach(e => {
      if (e.page.toString() === page.toString())
        cant[e.type] = cant[e.type] + 1;
    });
    return (
      <div className={'p-2 text-center w-100'}>
        <span className={'mr-3'}>
          <i className="fas fa-comment-alt" />
          <span
            style={{ fontSize: '15px' }}
            className={'ml-1 text-info font-weight-bold'}
          >
            {cant.notes}
          </span>
        </span>
        <span className={'mr-3'}>
          <i className="fas fa-tasks" />
          <span
            style={{ fontSize: '15px' }}
            className={'ml-1 text-info font-weight-bold'}
          >
            {cant.tasks}
          </span>
        </span>
        <span className={''}>
          <i className="fas fa-image" />
          <span
            style={{ fontSize: '15px' }}
            className={'ml-1 text-info font-weight-bold'}
          >
            {cant.images}
          </span>
        </span>
      </div>
    );
  };

  render() {
    let { loading, planDelete, uploadPlan, projectId, plans } = this.state;

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <Button
              size="sm"
              color={'info'}
              onClick={() => this.setState({ uploadPlan: true })}
            >
              <Trans>Add</Trans>
            </Button>
            <Button
              className={'ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>
          <CardBody>
            {plans.map(p => (
              <Row key={'plan-details-' + p._id}>
                <Col xs={9}>
                  <h6>{p.name}</h6>
                  <div dangerouslySetInnerHTML={{ __html: p.description }} />
                </Col>
                <Col>
                  <Button
                    key={'button1-' + p._id}
                    id={'delete' + p._id}
                    className={'m-0 btn-icon float-right'}
                    color="danger"
                    size="sm"
                    onClick={() => this.setState({ planDelete: p._id })}
                  >
                    <i className="fa fa-trash icon-action" />
                  </Button>
                  <UncontrolledTooltip
                    key={'tooltip1-' + p._id}
                    placement="left"
                    target={'delete' + p._id}
                    delay={0}
                  >
                    <Trans>Delete</Trans>
                  </UncontrolledTooltip>
                </Col>
                <Col xs={12}>
                  <Row>
                    {p.pages.map((page, index) => (
                      <Col
                        key={'plan-details-page-' + p._id + '-' + index}
                        xs={6}
                        md={2}
                      >
                        {this.elementsByPage(p.elements, index)}
                        <Link
                          to={`/projects/${projectId}/plan/${p._id}?page=${index}`}
                          className="nav-link p-0 shadow bg-white"
                        >
                          <img
                            alt={''}
                            style={{ width: '100%', height: 'auto' }}
                            src={page.Location}
                          />
                        </Link>
                      </Col>
                    ))}
                  </Row>
                  <hr className={'mt-4 mb-4'} />
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>

        {uploadPlan ? (
          <PlanModal
            show={uploadPlan}
            projectId={projectId}
            onCancel={() => this.setState({ uploadPlan: false })}
            onConfirm={() =>
              this.setState({ uploadPlan: false }, () => this.load())
            }
          />
        ) : null}

        {planDelete ? (
          <AlertConfirm
            message={'The plan cannot be recovered'}
            onCancel={() => this.setState({ planDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Plans));
