import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Row,
  Col
} from 'reactstrap';
import { AlertConfirm, Spinner, TableCustom } from '../../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';
import {
  duplicateDevis,
  estimateDevis,
  loadAllDevis,
  removeDevis,
  saveOneDevis
} from '../../../helpers/actions/projects';
import get from 'lodash/get';
import { dateFormatter, moneyFormatter } from '../../../helpers/formatters';
import Report from './Report';
import PreviewModal from './PreviewModal';
import EstimationModal from './EstimationModal';
import DevisStatusModal from './DevisStatusModal';

class DevisList extends Component {
  state = {
    loading: false,
    project: this.props.project,
    devis: [],
    user: null,
    devisPreview: null,
    devisDelete: null,
    devisReport: false,
    devisStatus: false,
    createEstimation: false
  };

  load() {
    this.setState(ps => ({ ...ps, loading: true }));

    let { project } = this.state,
      { dispatch } = this.props;

    dispatch(
      loadAllDevis(project._id, {
        include: ['user'],
        fields: ['title', 'created_at', 'user', 'status', 'base', 'price']
      })
    )
      .then(({ data }) =>
        this.setState(ps => ({ ...ps, devis: data, loading: false }))
      )
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  componentDidMount() {
    this.load();
  }

  duplicateDevi(devi) {
    let { dispatch, project } = this.props;
    dispatch(duplicateDevis(project._id, devi))
      .then(() => this.load())
      .catch(() => {});
  }

  handlerDeletDevis(d) {
    let id = d._id;
    this.setState({ devisDelete: id });
  }

  remove() {
    this.setState({ loading: true });

    let { dispatch, project } = this.props;

    dispatch(removeDevis(project._id, this.state.devisDelete))
      .then(() => this.setState({ devisDelete: null }, () => this.load()))
      .catch(() => this.setState({ devisDelete: null, loading: false }));
  }

  getDevisData() {
    let { devis } = this.state,
      { project, t } = this.props;

    return devis.map((d, key) => {
      return {
        className: '',
        data: [
          {
            className: '',
            fixed: true,
            value: (
              <Link to={`/projects/${project._id}/devis/${d._id}/details`}>
                {get(d, 'title', '')}
              </Link>
            )
          },
          {
            className: '',
            fixed: true,
            value: dateFormatter(d.created_at)
          },
          {
            className: '',
            fixed: false,
            value: get(d.user, 'displayName', '')
          },
          {
            className: '',
            fixed: false,
            value: (
              <span
                className={'status-button'}
                onClick={() => this.setState(ps => ({ ...ps, devisStatus: d }))}
              >
                {t(get(d, 'status', ''))}
              </span>
            )
          },
          {
            className: 'text-center',
            fixed: false,
            value: d.base ? <i className="fa fa fa-check" /> : null
          },
          {
            className: 'text-right',
            fixed: true,
            value: moneyFormatter(get(d, 'price.ttc', 0))
          },
          {
            className: 'text-right',
            fixed: true,
            value: moneyFormatter(
              get(d, 'price.ttc', 0) + get(d, 'price.materials', 0)
            )
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Link
                key={`edit-${key}`}
                id={`edit-${key}`}
                className="btn btn-icon ml-1 btn-info btn-sm"
                to={`/projects/${project._id}/devis/${d._id}`}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Link>,
              <UncontrolledTooltip
                key={`tooltip-edit-${key}`}
                placement="left"
                target={`edit-${key}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>,
              <UncontrolledDropdown key={`more-${key}`}>
                <DropdownToggle
                  color="info"
                  className="ml-1 btn-sm dropdown-icon"
                  caret
                >
                  <i className="fa fa-ellipsis-h icon-action mr-1" />
                </DropdownToggle>
                <DropdownMenu right={true}>
                  <DropdownItem
                    onClick={() =>
                      this.setState(ps => ({ ...ps, devisStatus: d }))
                    }
                  >
                    <Trans>Change Status</Trans>
                  </DropdownItem>
                  {d.status !== 'APPROVED' ? (
                    <DropdownItem onClick={() => this.handlerDeletDevis(d)}>
                      <Trans>Delete</Trans>
                    </DropdownItem>
                  ) : null}
                  <DropdownItem onClick={() => this.duplicateDevi(d._id)}>
                    <Trans>Duplicate</Trans>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      this.setState(ps => ({ ...ps, devisPreview: d._id }))
                    }
                  >
                    <Trans>Preview</Trans>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to={`/projects/${project._id}/devis/${d._id}/print?type=provider`}
                    >
                      <Trans>Print</Trans> <Trans>Provider</Trans>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to={`/projects/${project._id}/devis/${d._id}/print?type=pro`}
                    >
                      <Trans>Print</Trans> <Trans>Pro</Trans>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ]
          }
        ]
      };
    });
  }

  createEstimation(report) {
    this.setState({ loading: true });
    let { dispatch, project } = this.props;

    dispatch(estimateDevis(project._id, { comments: report.text }))
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  changeDevisStatus(value) {
    this.setState(ps => ({ ...ps, loading: true }));

    let { devisStatus } = this.state,
      { dispatch, project } = this.props;

    let status = value ? value : 'CREATED';

    dispatch(
      saveOneDevis(project._id, {
        status: status,
        _id: devisStatus._id,
        project: project._id
      })
    )
      .then(() => {
        this.setState(
          ps => ({
            ...ps,
            devisStatus: false,
            loading: false
          }),
          () => {
            this.load();
            this.props.reload();
          }
        );
      })
      .catch(() =>
        this.setState(ps => ({ ...ps, devisStatus: false, loading: false }))
      );
  }

  render() {
    let {
        loading,
        project,
        devis,
        devisPreview,
        devisDelete,
        devisReport,
        createEstimation,
        devisStatus
      } = this.state,
      { t } = this.props;
    let rows = this.getDevisData();

    return [
      <Card key="devisList" className={'card-flex'}>
        {loading ? <Spinner inside={true} /> : null}
        <CardHeader>
          <Row>
            <Col xs={2} className={'d-flex align-content-center'}>
              <h5 className={'m-0'}>
                <Trans>Devis</Trans>
              </h5>
            </Col>
            <Col>
              <Button
                className={'float-right mt-0'}
                size="sm"
                color={'default'}
                onClick={() => this.load()}
              >
                <i className="now-ui-icons arrows-1_refresh-69" />
              </Button>
              <Button
                className="float-right mt-0"
                size="sm"
                color={'info'}
                href={`/projects/${project._id}/devis`}
              >
                <Trans>Add</Trans>
              </Button>
              {devis &&
              devis.length &&
              devis.find(d => d.status === 'APPROVED') ? (
                <Button
                  tag={Link}
                  className="float-right mt-0"
                  size="sm"
                  color={'info'}
                  to={`/projects/${project._id}/orders`}
                >
                  <Trans>Orders</Trans>
                </Button>
              ) : null}
              {devis && devis.length ? (
                <Button
                  tag={Link}
                  className="float-right mt-0"
                  size="sm"
                  color={'info'}
                  to={`/projects/${project._id}/accounting`}
                >
                  <Trans>Accounting</Trans>
                </Button>
              ) : null}
              {devis &&
              devis.length &&
              devis.find(d => d.status === 'APPROVED') &&
              !project.documents.find(d => d.kind === 0) ? (
                <Button
                  className="float-right mt-0"
                  size="sm"
                  color={'info'}
                  onClick={() => this.setState({ devisReport: true })}
                >
                  <Trans>Report</Trans>
                </Button>
              ) : null}
              {project.devisBase && !project.devis.length ? (
                <Button
                  className="float-right mt-0"
                  size="sm"
                  color={'info'}
                  onClick={() => this.setState({ createEstimation: true })}
                >
                  <Trans>Estimation</Trans>
                </Button>
              ) : null}
            </Col>
          </Row>
          <hr className={'mt-1 d-none d-md-block'} />
        </CardHeader>
        <CardBody>
          <TableCustom
            minWidth={767}
            className={'devi-item-material-table'}
            accordion={true}
            heads={[
              { value: t('Title') },
              { value: t('Date') },
              { value: t('User') },
              { value: t('Status') },
              { value: t('Base'), className: 'text-center' },
              { value: t('TTC'), className: 'text-right' },
              { value: t('TTC + Matériaux'), className: 'text-right' },
              { value: '', className: 'text-right' }
            ]}
            rows={rows}
            emptyMessage={t('No devis found')}
          />
        </CardBody>
      </Card>,
      !!devisPreview ? (
        <PreviewModal
          key={'previewModal'}
          show={!!devisPreview}
          project={project}
          devisId={devisPreview}
          dispatch={this.props.dispatch}
          onClose={() => this.setState(ps => ({ ...ps, devisPreview: null }))}
        />
      ) : null,
      devisReport ? (
        <Report
          key={'report'}
          show={devisReport}
          projectId={project._id}
          onClose={() => this.setState(ps => ({ ...ps, devisReport: false }))}
        />
      ) : null,
      createEstimation ? (
        <EstimationModal
          key={'estimationModal'}
          show={createEstimation}
          onCancel={() =>
            this.setState(ps => ({ ...ps, createEstimation: false }))
          }
          onConfirm={estimation =>
            this.setState(
              ps => ({ ...ps, createEstimation: false }),
              () => this.createEstimation(estimation)
            )
          }
        />
      ) : null,
      devisDelete ? (
        <AlertConfirm
          key={'alertConfirm'}
          message={'The devis cannot be recovered'}
          onCancel={() => this.setState(ps => ({ ...ps, devisDelete: null }))}
          onConfirm={() => this.remove()}
        />
      ) : null,
      !!devisStatus ? (
        <DevisStatusModal
          key={'statusModal'}
          show={!!devisStatus}
          status={devisStatus ? devisStatus.status : null}
          onCancel={() => this.setState(ps => ({ ...ps, devisStatus: false }))}
          onConfirm={value => this.changeDevisStatus(value)}
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(DevisList));
