import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Accordion } from '../../../components';
import Medias from '../Medias/Medias';
import Events from '../Events/Events';
import Documents from '../Documents/Documents';
import Notes from '../Notes/Notes';
import DevisList from '../Devis/DevisList';
import Provider from '../Provider/Provider';
import AssignClient from '../Client/AssignClient';
import AssignPro from '../Professional/AssignPro';
import AssignSupervisor from '../Supervisor/AssignSupervisor';
import AssignSeller from '../Seller/AssignSeller';
import Maps from '../Map3D/Maps';
import PostulatedPros from '../PostulatedPros/PostulatedPros';
import MapsPlans from '../MapsPlans';
import { isMobileOnly } from 'react-device-detect';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: {},
      hTabs: 'medias'
    };
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({ project: nextProps.project });
  }

  render() {
    let { project, hTabs } = this.state,
      { t } = this.props;

    return (
      <div>
        {isMobileOnly ? (
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Accordion
                    plain
                    components={[
                      {
                        title: t('Client'),
                        text: (
                          <AssignClient
                            project={project}
                            reload={() => this.props.reload()}
                          />
                        )
                      },
                      {
                        title: t('Professional'),
                        text: (
                          <AssignPro
                            project={project}
                            reload={() => this.props.reload()}
                          />
                        )
                      },
                      {
                        title: t('Supervisors'),
                        text: (
                          <AssignSupervisor
                            project={project}
                            reload={() => this.props.reload()}
                          />
                        )
                      },
                      {
                        title: t('Seller'),
                        text: (
                          <AssignSeller
                            project={project}
                            reload={() => this.props.reload()}
                          />
                        )
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={6} lg={3} className="flex-column mb-4">
              <AssignClient
                project={project}
                reload={() => this.props.reload()}
              />
            </Col>
            <Col xs={12} md={6} lg={3} className="flex-column mb-4">
              <AssignPro project={project} reload={() => this.props.reload()} />
            </Col>
            <Col xs={12} md={6} lg={3} className="flex-column mb-4">
              <AssignSupervisor
                project={project}
                reload={() => this.props.reload()}
              />
            </Col>
            <Col xs={12} md={6} lg={3} className="flex-column mb-4">
              <AssignSeller
                project={project}
                reload={() => this.props.reload()}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="mb-4">
            {project && project._id ? (
              <DevisList project={project} reload={() => this.props.reload()} />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Nav pills className="nav-pills-info project-pills">
                  <NavItem>
                    <NavLink
                      className={hTabs === 'notes' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'notes' })}
                    >
                      <Trans>Notes</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={hTabs === 'maps_plans' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'maps_plans' })}
                    >
                      <Trans>Maps Plans</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={hTabs === 'events' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'events' })}
                    >
                      <Trans>Events</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={hTabs === 'medias' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'medias' })}
                    >
                      <Trans>Medias</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={hTabs === 'documents' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'documents' })}
                    >
                      <Trans>Documents</Trans>
                    </NavLink>
                  </NavItem>
                  {project && project._id && !project.pro ? (
                    <NavItem>
                      <NavLink
                        className={hTabs === 'pros' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'pros' })}
                      >
                        <Trans>Postulated Pros</Trans>
                      </NavLink>
                    </NavItem>
                  ) : null}
                  <NavItem>
                    <NavLink
                      className={hTabs === 'provider' ? 'active' : ''}
                      onClick={() => this.setState({ hTabs: 'provider' })}
                    >
                      <Trans>Provider</Trans>
                    </NavLink>
                  </NavItem>
                </Nav>
                <hr />
                <TabContent activeTab={hTabs}>
                  <TabPane tabId="medias">
                    {project && project._id ? (
                      <Medias projectId={project._id} medias={project.medias} />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="documents">
                    {project && project._id ? (
                      <Documents
                        projectId={project._id}
                        documents={project.documents}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="notes">
                    {project && project._id ? (
                      <Notes projectId={project._id} notes={project.notes} />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="events">
                    {project && project._id ? (
                      <Events
                        projectId={project._id}
                        projAddress={project.address}
                        projName={project.name}
                        clientEmail={project.client ? project.client.email : ''}
                        events={project.events}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="provider">
                    {project && project._id ? (
                      <Provider project={project} />
                    ) : null}
                  </TabPane>

                  {project && project._id && !project.pro ? (
                    <TabPane tabId="pros">
                      <PostulatedPros
                        projectId={project._id}
                        className={'card-plain user-data'}
                      />
                    </TabPane>
                  ) : null}

                  <TabPane tabId="maps_plans">
                    {project && project._id ? (
                      <MapsPlans
                        projectId={project._id}
                        plans={project.plans}
                        documents={project.documents}
                        className={'card-plain user-data'}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {project && project._id ? (
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Maps
                    projectId={project._id}
                    maps={project.maps}
                    className={'card-plain user-data'}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Details));
