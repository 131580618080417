import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Spinner } from '../../../components';
import ReactQuill from 'react-quill';
import { report } from '../../../helpers/actions/projects';
import { quillConfig } from '../../../helpers/formatters';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: ''
    };
  }

  closeModal() {
    this.setState({ comments: '', loading: false }, () => this.props.onClose());
  }

  createReport() {
    this.setState({ loading: true });

    let { comments } = this.state,
      { projectId, dispatch } = this.props;
    dispatch(report(projectId, { comments }))
      .then(() => this.closeModal())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { loading, comments } = this.state;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Create Project Report</Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <FormGroup className={'has-label'}>
                <Label>
                  <Trans>Comments</Trans>
                </Label>
                <ReactQuill
                  value={comments || ''}
                  modules={quillConfig}
                  className={'quill-text-editor'}
                  onChange={value => {
                    this.setState({ comments: value });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.createReport()}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(Report));
