import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner,
  TableCustom
} from '../../../components';
import get from 'lodash/get';
import { all, photo, remove, save } from '../../../helpers/actions/materials';
import { moneyFormatter } from '../../../helpers/formatters';
import MaterialModal from './MaterialModal';
import defaultImage from '../../../assets/img/image_placeholder.jpg';

class Materials extends Component {
  state = {
    loading: true,
    materials: [],
    count: 0,
    page: 1,
    limit: 15,
    search: '',
    material: null,
    materialDelete: null
  };

  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ materials: data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  onSave(materialEdit, photoFile) {
    this.setState({ loading: true });

    let { dispatch } = this.props;

    //delete materialEdit.photo;
    dispatch(save(materialEdit))
      .then(materialRes => {
        if (materialRes._id && photoFile) {
          dispatch(photo(materialRes._id, photoFile))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deleteMaterial(id) {
    this.setState({ loading: true, materialDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  getMaterialsData() {
    let { materials } = this.state;

    return materials.map((material, key) => {
      return {
        className: '',
        data: [
          {
            className: '',
            value: (
              <div
                className="piece-surface-image"
                style={{
                  backgroundImage: `url('${get(
                    material,
                    'image',
                    defaultImage
                  )}')`
                }}
              />
            )
          },
          { className: '', fixed: true, value: material.name },
          {
            className: 'text-right',
            fixed: false,
            value: <h6>{moneyFormatter(material.unit_price)}</h6>
          },
          { className: 'text-center', fixed: false, value: material.unit },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Button
                key={`edit-${key}`}
                id={'edit' + material._id}
                className={'ml-1 btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.setState({ material: material })}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`editTooltip-${key}`}
                placement="left"
                target={'edit' + material._id}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>,
              <Button
                key={`delete-${key}`}
                id={'delete' + material._id}
                className={'ml-1 btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.setState({ materialDelete: material })}
              >
                <i className="fa fa-trash icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`deleteTooltip-${key}`}
                placement="left"
                target={'delete' + material._id}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            ]
          }
        ]
      };
    });
  }

  render() {
    let { loading, page, limit, count, material, materialDelete } = this.state,
      { t } = this.props;
    let rows = this.getMaterialsData();

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <Form>
                <Row>
                  <Col className={'text-left'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              search: event.target.value
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ material: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardHeader>
            <CardBody>
              <TableCustom
                minWidth={767}
                className={'devi-item-material-table'}
                accordion={true}
                principal={1}
                heads={[
                  { value: t('Image') },
                  { value: t('Name') },
                  { value: t('Unit Price'), className: 'text-right' },
                  { value: t('Unit'), className: 'text-center' },
                  { value: '', className: 'text-right' }
                ]}
                rows={rows}
                emptyMessage={t('No payments found')}
              />
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          {!!material ? (
            <MaterialModal
              show={!!material}
              material={material}
              onCancel={() => this.setState({ material: null })}
              onConfirm={(materialEdit, photo) =>
                this.setState({ material: null }, () =>
                  this.onSave(materialEdit, photo)
                )
              }
            />
          ) : null}

          {!materialDelete ? null : (
            <AlertConfirm
              message={'The material cannot be recovered'}
              onCancel={() => this.setState({ materialDelete: null })}
              onConfirm={() => this.deleteMaterial(materialDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Materials));
