import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import get from 'lodash/get';
import defaultImage from '../../assets/img/image_placeholder.jpg';

class PieceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  render() {
    let { selected } = this.state,
      { piece, t } = this.props;

    return (
      <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState({ selected: !selected })}
        >
          <Row>
            <Col xs={3} lg={2} className={'pl-1 pr-1'}>
              <div
                className="piece-surface-image"
                style={{
                  backgroundImage: `url('${
                    piece.photo ? piece.photo : defaultImage
                  }')`
                }}
              />
            </Col>
            <Col xs={9} lg={7}>
              <h6>{piece.name}</h6>
              <span
                className={`badge ${
                  piece.inPackage ? 'badge-success' : 'badge-default'
                }`}
              >
                {piece.inPackage
                  ? t('Enable for package')
                  : t('Disable for package')}
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className={'text-right pl-1 pr-1'}
            >
              <Button
                id={`edit-${piece._id}`}
                className={'btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(piece)}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>{' '}
              <UncontrolledTooltip
                placement="left"
                target={`edit-${piece._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${piece._id}`}
                className={'btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(piece)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${piece._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ display: selected ? 'block' : 'none' }}>
          <hr />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <strong>
                <Trans>Description</Trans>
              </strong>
              <p>{get(piece, 'description', '-')}</p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <strong>
                <Trans>Type</Trans>
              </strong>
              <p>{t(get(piece, 'type', '-'))}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(PieceCard));
