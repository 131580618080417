import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Plans from '../Plans/Plans';
import Maps from '../Map2D/Maps';

class MapsPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Maps
          projectId={this.props.projectId}
          documents={this.props.documents}
          className={'card-plain user-data'}
        />
        <hr className={'mt-3 mb-3'} />
        <Plans
          projectId={this.props.projectId}
          plans={this.props.plans}
          className={'card-plain user-data'}
        />
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MapsPlans));
