import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import get from 'lodash/get';
import join from 'lodash/join';
import cloneDeep from 'lodash/cloneDeep';
import { moneyFormatter } from '../../helpers/formatters';

class OperationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  duplicateOperation() {
    let { operation } = this.props;
    let entity = cloneDeep(operation);

    delete entity._id;
    delete entity.type;
    this.props.onEdit(entity);
  }

  render() {
    let { selected } = this.state,
      { operation, t } = this.props;

    return (
      <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState({ selected: !selected })}
        >
          <Row>
            <Col xs={12} lg={9}>
              <h6>{operation.name}</h6>
              {t(get(operation, 'type', ''))}
            </Col>
            <Col xs={12} lg={3} className={'text-right pl-1 pr-1'}>
              <Button
                id={`edit-${operation._id}`}
                className={'btn-icon mt-0 mb-0'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(operation)}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>{' '}
              <UncontrolledTooltip
                placement="left"
                target={`edit-${operation._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`duplication-${operation._id}`}
                className={'btn-icon mt-0 mb-0'}
                color="info"
                size="sm"
                onClick={() => this.duplicateOperation()}
              >
                <i className="fa fa-clone icon-action" />
              </Button>{' '}
              <UncontrolledTooltip
                placement="left"
                target={`duplication-${operation._id}`}
                delay={0}
              >
                <Trans>Duplicate</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${operation._id}`}
                className={'btn-icon mt-0 mb-0'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(operation)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${operation._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={'pt-0 pb-0'}
          style={{ display: selected ? 'block' : 'none' }}
        >
          <hr />
          <p>
            <strong>
              <Trans>Description</Trans>
            </strong>
            {`: ${get(operation, 'description', '-')}`}
          </p>
          <hr />
          <p>
            <strong>
              <Trans>Unit Price</Trans>
            </strong>
            {`: ${moneyFormatter(operation.unit_price)} / ${get(
              operation,
              'unit',
              '-'
            )}`}
          </p>
          <hr />
          <p>
            <strong>
              <Trans>Surface</Trans>
            </strong>
            {`: ${operation.group ? operation.group : '-'}`}
          </p>
          <hr />
          <p>
            <strong>
              <Trans>Pieces</Trans>
            </strong>
            {`: ${join(operation.pieces, ' / ')}`}
          </p>
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(OperationCard));
