import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import 'moment/locale/fr';
import { modelSystems } from '../../../helpers/nomenclators';
import Select from 'react-select';

class MapModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {}
    };
  }

  componentWillMount() {
    let { map } = this.props;
    this.setState({ map });
  }

  cleanModal() {
    this.setState({
      titleValid: null,
      codeValid: null,
      keyValid: null,
      map: {}
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { map } = this.state;

    this.setState({
      map: { ...map, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let title = this.title;
    let code = this.code;

    this.setState({
      [title.attributes.getNamedItem('namevalid').value]: title.validity.valid
        ? 'has-success'
        : 'has-danger',
      [code.attributes.getNamedItem('namevalid').value]: code.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      title.validity.valid &&
      code.validity.valid &&
      this.state.map.system.length
    );
  }

  onSaveMap() {
    let { map } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(map);
    }
  }

  render() {
    let { map } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'md'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {map && map._id ? t('Edit map') : t('New map')}
        </ModalHeader>
        <ModalBody>
          {map ? (
            <Row>
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.titleValid
                  }
                >
                  <Label>
                    <Trans>Title</Trans>
                  </Label>
                  <Input
                    type="text"
                    innerRef={node => (this.title = node)}
                    namevalid="titleValid"
                    value={map.title || ''}
                    required="required"
                    onChange={event =>
                      this.onChange(
                        'title',
                        'titleValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className={'has-label '}>
                  <Label>
                    <Trans>Model System</Trans>
                  </Label>
                  <Select
                    className="primary"
                    clearable={false}
                    innerRef={node => (this.system = node)}
                    options={modelSystems.map(p => ({
                      label: t(p),
                      value: p
                    }))}
                    value={map.system}
                    onChange={event =>
                      this.onChange('system', 'systemValid', event.value, true)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.titleValid
                  }
                >
                  <Label>
                    <Trans>Code</Trans>
                  </Label>
                  <Input
                    type="text"
                    innerRef={node => (this.code = node)}
                    namevalid="codeValid"
                    value={map.code || ''}
                    required="required"
                    onChange={event =>
                      this.onChange(
                        'code',
                        'codeValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveMap(map)}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(MapModal));
