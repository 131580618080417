import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import { Spinner } from '../../components';
import Select from 'react-select';
import { all as allPieces } from '../../helpers/actions/pieces';
import { all as allSurfaces } from '../../helpers/actions/surfaces';
import { all as allOperations } from '../../helpers/actions/operation';
import { projectType } from '../../helpers/nomenclators';

class PackageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      surfaces: [],
      pieces: [],
      pack: {
        operations: []
      },
      catalog: []
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    let { pack, dispatch } = this.props;
    let promises = [];

    if (pack && pack.type) {
      promises.push(dispatch(allPieces({ type: pack.type })));
      promises.push(dispatch(allSurfaces({ type: pack.type })));
      promises.push(dispatch(allOperations({ type: pack.type, limit: 10000 })));
    }

    Promise.all(promises)
      .then(([pieces, surfaces, operations]) => {
        this.setState({
          pack:
            pack && pack._id
              ? {
                  ...pack,
                  group: pack.group ? pack.group._id : null,
                  piece: pack.piece ? pack.piece._id : null
                }
              : { operations: [] },
          pieces: pieces && pieces.data ? pieces.data : [],
          surfaces: surfaces && surfaces.data ? surfaces.data : [],
          catalog: operations && operations.data ? operations.data : [],
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  onChange(name, nameValid, value, valid) {
    let { pack } = this.state;

    this.setState({
      pack: { ...pack, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  onChangeType(value, valid) {
    let { pack } = this.state,
      { dispatch } = this.props;

    if (!valid) {
      this.setState(prevState => {
        return {
          ...prevState,
          pack: {
            ...prevState.pack,
            type: value,
            group: null,
            piece: null,
            operations: []
          },
          typeValid: valid ? 'has-success' : 'has-danger',
          groupValid: null,
          piecesValid: null,
          surfaces: [],
          pieces: [],
          catalog: []
        };
      });
    } else if (pack.type !== value) {
      this.setState({ loading: true });

      Promise.all([
        dispatch(allPieces({ type: value })),
        dispatch(allSurfaces({ type: value })),
        dispatch(allOperations({ type: value, limit: 10000 }))
      ])
        .then(([pieces, surfaces, operations]) => {
          this.setState(prevState => {
            return {
              ...prevState,
              pack: {
                ...prevState.pack,
                type: value,
                group: null,
                piece: null,
                operations: []
              },
              typeValid: valid ? 'has-success' : 'has-danger',
              groupValid: null,
              piecesValid: null,
              surfaces: surfaces.data,
              pieces: pieces.data,
              catalog: operations.data,
              loading: false
            };
          });
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  onChangeSurPie(name, nameValid, value, valid) {
    let { pack } = this.state;

    this.setState({
      pack: { ...pack, [name]: value, operations: [] },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  filterOperations() {
    let { pack, catalog, pieces, surfaces } = this.state;
    let operations = [];

    if (pack.piece && pack.group && catalog.length) {
      let piece = pieces.find(t => t._id === pack.piece);
      let surface = surfaces.find(t => t._id === pack.group);

      if (piece && surface) {
        operations = catalog.filter(
          t =>
            t.group === surface.name &&
            t.pieces.findIndex(p => p === piece.name) > -1
        );
      }
    }
    return operations;
  }

  onSelectOp(event, operation) {
    let { pack } = this.state;

    if (event.target.checked) {
      pack.operations.push(operation);
    } else {
      const index = pack.operations.findIndex(op => op._id === operation._id);
      pack.operations.splice(index, 1);
    }

    this.setState({ pack });
  }

  close() {
    this.setState({
      nameValid: null,
      groupValid: null,
      pieceValid: null,
      descriptionValid: null
    });
    this.props.closeModal();
  }

  validate() {
    let { pack } = this.state;
    let name = this.name;
    let description = this.description;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      groupValid: pack.group ? 'has-success' : 'has-danger',
      pieceValid: pack.piece ? 'has-success' : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      pack.group &&
      pack.piece &&
      description.validity.valid
    );
  }

  render() {
    let { loading, pieces, surfaces, pack } = this.state,
      { t } = this.props;
    let operations = this.filterOperations();

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {pack._id ? t('Edit Package') : t('New Package')}
        </ModalHeader>
        <ModalBody>
          {loading ? <Spinner /> : null}
          {pack ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.nameValid
                    }
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={pack.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.typeValid
                    }
                  >
                    <Label>
                      <Trans>Type</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={projectType.map(pt => ({
                        label: t(pt),
                        value: pt
                      }))}
                      value={pack.type}
                      onChange={event =>
                        this.onChangeType(event ? event.value : null, !!event)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.groupValid
                    }
                  >
                    <Label>
                      <Trans>Surface</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={surfaces.map(g => ({
                        label: g.name,
                        value: g._id
                      }))}
                      value={pack.group}
                      onChange={event =>
                        this.onChangeSurPie(
                          'group',
                          'groupValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.pieceValid
                    }
                  >
                    <Label>
                      <Trans>Pieces</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={pieces.map(p => ({
                        label: p.name,
                        value: p._id
                      }))}
                      value={pack.piece}
                      onChange={event =>
                        this.onChangeSurPie(
                          'piece',
                          'pieceValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={pack.description || ''}
                      onChange={event =>
                        this.onChange(
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Label>
                    <Trans>Operations</Trans>
                  </Label>
                  <Table responsive>
                    <tbody>
                      {pack.group && pack.piece ? (
                        operations.length ? (
                          operations.map((operation, key) => {
                            return (
                              <tr key={key}>
                                <td className="text-left">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        checked={
                                          pack.operations.findIndex(
                                            op => op._id === operation._id
                                          ) > -1
                                        }
                                        onChange={event =>
                                          this.onSelectOp(event, operation)
                                        }
                                      />
                                      <span className="form-check-sign" />
                                    </Label>
                                  </FormGroup>
                                </td>
                                <td className="text-left">{operation.name}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              <Trans>No operations found</Trans>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-center">
                            <Trans>Select a piece and a surface</Trans>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validate()) {
                this.setState({
                  nameValid: null,
                  groupValid: null,
                  pieceValid: null,
                  descriptionValid: null
                });
                this.props.savePackage(pack);
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(PackageModal));
