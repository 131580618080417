import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { Spinner, TableCustom } from '../../../components';
import get from 'lodash/get';
import { postulatedUsers } from '../../../helpers/actions/projects';
import { proName } from '../../../helpers/formatters';

class PostulatedPros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pros: []
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;

    dispatch(postulatedUsers(projectId))
      .then(pros => this.setState(ps => ({ ...ps, pros, loading: false })))
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  getProsData() {
    let { pros } = this.state;

    return pros.map(pro => {
      return {
        className: '',
        data: [
          { className: '', value: proName(pro) },
          { className: '', fixed: true, value: get(pro, 'email', '') },
          { className: '', fixed: true, value: get(pro, 'phone.mobile', '') },
          { className: '', fixed: true, value: get(pro, 'address', '') }
        ]
      };
    });
  }

  render() {
    let { loading } = this.state,
      { t } = this.props;
    let rows = this.getProsData();

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner /> : null}
          <CardBody>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[
                { value: t('Name') },
                { value: t('Email') },
                { value: t('Mobile') },
                { value: t('Address') }
              ]}
              rows={rows}
              emptyMessage={t('No postulated professionals found')}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(PostulatedPros));
