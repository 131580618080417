import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import CreatableSelect from 'react-select/lib/Creatable';
import get from 'lodash/get';
import { units } from '../../../helpers/nomenclators';
import Gallery from '../../Images/Gallery';
import defaultImage from '../../../assets/img/image_placeholder.jpg';

class MaterialModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: {},
      file: null,
      showGallery: false
    };
  }

  componentWillMount() {
    let { material } = this.props;
    if (
      material &&
      material.unit &&
      !units.find(unit => unit === material.unit)
    ) {
      units.push(material.unit);
    }
    this.setState({ material });
  }

  cleanModal() {
    this.setState({
      nameValid: null,
      unitValid: null,
      unitPriceValid: null,
      material: {},
      file: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  handleImageChange(file) {
    let { material } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      material.image = reader.result;
      this.setState({ material, file: file });
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  onChange(name, nameValid, value, valid) {
    let { material } = this.state;

    this.setState({
      material: { ...material, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let { material } = this.state;
    let name = this.name;
    let unitPrice = this.unit_price;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [unitPrice.attributes.getNamedItem('namevalid').value]: unitPrice.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      unitValid: material.unit ? 'has-success' : 'has-danger'
    });

    return name.validity.valid && unitPrice.validity.valid && material.unit;
  }

  onSaveMaterial() {
    let { material, file } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(material, file);
    }
  }

  render() {
    let { material, showGallery } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {material && material._id ? t('Edit Material') : t('Add Material')}
        </ModalHeader>
        <ModalBody>
          {material ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} className={'display-flex justify-content-center'}>
                  <div
                    className="avatar-image"
                    style={{
                      backgroundImage: `url('${get(
                        material,
                        'image',
                        defaultImage
                      )}')`
                    }}
                  />
                </Col>
                <Col xs={12} className={'justify-content-center text-center'}>
                  <Button
                    color={'info'}
                    round="true"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Change</Trans>
                  </Button>
                  <Button
                    color="info"
                    onClick={() => this.setState({ showGallery: true })}
                  >
                    <Trans>Gallery</Trans>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.nameValid
                    }
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={material.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.unitValid
                    }
                  >
                    <Label>
                      <Trans>Unit</Trans>
                    </Label>
                    <CreatableSelect
                      namevalid="unitValid"
                      innerRef={node => (this.unit = node)}
                      className="primary"
                      closeOnSelect={true}
                      value={material.unit}
                      options={units.map(p => ({ label: p, value: p }))}
                      onNewOptionClick={event => {
                        units.push(event.value);
                        this.onChange(
                          'unit',
                          'unitValid',
                          event ? event.value : null,
                          !!event
                        );
                      }}
                      onChange={event =>
                        this.onChange(
                          'unit',
                          'unitValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.unitPriceValid
                    }
                  >
                    <Label>
                      <Trans>Unit Price</Trans>
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.unit_price = node)}
                      namevalid="unitPriceValid"
                      value={material.unit_price || ''}
                      step={0.01}
                      min={0}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'unit_price',
                          'unitPriceValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveMaterial()}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>

        {showGallery ? (
          <Gallery
            type={'material'}
            selectImage={url =>
              this.setState(prevState => {
                return {
                  ...prevState,
                  showGallery: false,
                  material: {
                    ...prevState.material,
                    image: url
                  }
                };
              })
            }
            closeGallery={() =>
              this.setState(prevState => {
                return { ...prevState, showGallery: false };
              })
            }
          />
        ) : null}
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(MaterialModal));
