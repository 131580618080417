import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import Select from 'react-select';
import { all, remove, save } from '../../helpers/actions/operation';
import { all as allSurfaces } from '../../helpers/actions/surfaces';
import { projectType } from '../../helpers/nomenclators';
import OperationCard from './OperationCard';
import OperationModal from './OperationModal';

class Operations extends Component {
  state = {
    loading: true,
    groups: [],
    operations: [],
    count: 0,
    page: 1,
    limit: 18,
    group: null,
    search: '',
    type: null,
    operation: null,
    operationDelete: null
  };

  componentDidMount() {
    this.setState({ loading: true });

    let { page, limit } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    Promise.all([dispatch(all({ limit, offset })), dispatch(allSurfaces({}))])
      .then(([{ meta, data }, groups]) =>
        this.setState({
          operations: data,
          ...meta,
          groups: groups.data,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  load() {
    this.setState({ loading: true, operations: [] });

    let { page, limit, group, search, type } = this.state,
      { dispatch } = this.props;

    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, group, search, type }))
      .then(({ data, meta }) => {
        this.setState({ operations: data, ...meta, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  onChangeTypeFilter(value) {
    this.setState({ loading: true });

    let { group } = this.state,
      { dispatch } = this.props;
    let promise = value
      ? dispatch(allSurfaces({ type: value }))
      : dispatch(allSurfaces({}));

    promise
      .then(groups => {
        let index =
          groups && groups.data
            ? groups.data.findIndex(t => t.name === group)
            : -1;
        group = index > -1 ? group : null;

        this.setState(
          { page: 1, type: value, group, groups: groups.data },
          () => this.load()
        );
      })
      .catch(() => this.setState({ loading: false }));
  }

  onSave(operationEdit) {
    this.setState({ loading: true });

    let { dispatch } = this.props;

    dispatch(save(operationEdit))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  deleteOperation(id) {
    this.setState({ loading: true, operationDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
        loading,
        page,
        limit,
        count,
        groups,
        operations,
        operation,
        operationDelete
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            { page: 1, search: event.target.value },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={projectType.map(pt => ({
                          label: t(pt),
                          value: pt
                        }))}
                        value={this.state.type}
                        onChange={event =>
                          this.onChangeTypeFilter(event ? event.value : null)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Surface</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={groups.map(g => ({
                          label: g.name,
                          value: g.name
                        }))}
                        value={this.state.group}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              group: event ? event.value : null
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={2}>
                    <Button
                      className="float-right"
                      color="info"
                      onClick={() => this.setState({ operation: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {operations.length > 0 ? (
            <div>
              <Row>
                {operations.map((op, key) => (
                  <Col key={key} xs={12} md={6} lg={4}>
                    <OperationCard
                      operation={op}
                      onEdit={operationEdit =>
                        this.setState({ operation: operationEdit })
                      }
                      onDelete={deleteOperation =>
                        this.setState({ operationDelete: deleteOperation })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No operations found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!operation ? (
            <OperationModal
              dispatch={this.props.dispatch}
              show={!!operation}
              operation={operation}
              onCancel={() => this.setState({ operation: null })}
              onConfirm={operationEdit =>
                this.setState({ operation: null }, () =>
                  this.onSave(operationEdit)
                )
              }
            />
          ) : null}

          {!operationDelete ? null : (
            <AlertConfirm
              message={'The operation cannot be recovered'}
              onCancel={() => this.setState({ operationDelete: null })}
              onConfirm={() => this.deleteOperation(operationDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Operations));
