import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip
} from 'reactstrap';
import { AlertConfirm, Spinner, TableCustom } from '../../../components';
import {
  loadDocuments,
  removeDocument,
  saveDocument,
  sendReport
} from '../../../helpers/actions/projects';
import SendEmail from '../SendEmails/SendEmail';
import DocumentModal from './DocumentModal';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      documents: [],
      projectId: null,
      sendByEmail: null,
      uploadDoc: false,
      docDelete: null
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    dispatch(
      loadDocuments(projectId, {
        type: JSON.stringify({ $nin: ['Plans', 'Bills'] })
      })
    )
      .then(documents =>
        this.setState({ documents, loading: false, projectId })
      )
      .catch(() => this.setState({ loading: false }));
  }

  remove() {
    this.setState({ loading: true });
    let { docDelete } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removeDocument(projectId, docDelete))
      .then(() => this.setState({ docDelete: null }, () => this.load()))
      .catch(() => this.setState({ docDelete: null, loading: false }));
  }

  sendReport(docId) {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;
    dispatch(sendReport(projectId, docId))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  validate(document) {
    let valid = !document.valide;
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;
    dispatch(saveDocument(projectId, { _id: document._id, valide: valid }))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  removeSendByEmail() {
    this.setState({ sendByEmail: null }, () => this.load());
  }

  createOptions(d, signed) {
    let { t } = this.props;
    let kind = d.kind ? d.kind.toString() : '12';
    let buttons = [];
    if (kind.indexOf('4') >= 0 && !signed) {
      buttons.push([
        <Button
          key={'button4-' + d._id}
          id={'send_by_email' + d._id}
          className={'ml-1 btn-icon'}
          color="info"
          size="sm"
          onClick={() =>
            this.setState({
              sendByEmail: d._id
            })
          }
        >
          <i className="fa fa-envelope icon-action" />
        </Button>,
        <UncontrolledTooltip
          key={'tooltip4-' + d._id}
          placement="left"
          target={'send_by_email' + d._id}
          delay={0}
        >
          <Trans>Send by email</Trans>
        </UncontrolledTooltip>
      ]);
    }
    if (kind.indexOf('3') >= 0 && !signed) {
      buttons.push([
        <Button
          key={'button3-' + d._id}
          id={'send_report' + d._id}
          className={'ml-1 btn-icon'}
          color="info"
          size="sm"
          onClick={() => this.sendReport(d._id)}
        >
          <i className="fa fa-paper-plane icon-action" />
        </Button>,
        <UncontrolledTooltip
          key={'tooltip3-' + d._id}
          placement="left"
          target={'send_report' + d._id}
          delay={0}
        >
          <Trans>Send report to client</Trans>
        </UncontrolledTooltip>
      ]);
    }
    if (kind.indexOf('2') >= 0) {
      buttons.push([
        <Button
          key={'button2-' + d._id}
          id={'validate' + d._id}
          className={'ml-1 btn-icon'}
          color="info"
          size="sm"
          onClick={() => this.validate(d)}
        >
          <i
            className={`fa ${
              !d.valide ? 'fa-check-circle' : 'fa-circle'
            } icon-action`}
          />
        </Button>,
        <UncontrolledTooltip
          key={'tooltip2-' + d._id}
          placement="left"
          target={'validate' + d._id}
          delay={0}
        >
          {!d.valide ? t('Validate') : t('Invalidate')}
        </UncontrolledTooltip>
      ]);
    }
    if (kind.indexOf('1') >= 0) {
      buttons.push([
        <Button
          key={'button1-' + d._id}
          id={'delete' + d._id}
          className={'ml-1 btn-icon'}
          color="danger"
          size="sm"
          onClick={() => this.setState({ docDelete: d._id })}
        >
          <i className="fa fa-trash icon-action" />
        </Button>,
        <UncontrolledTooltip
          key={'tooltip1-' + d._id}
          placement="left"
          target={'delete' + d._id}
          delay={0}
        >
          <Trans>Delete</Trans>
        </UncontrolledTooltip>
      ]);
    }
    return buttons;
  }

  getDocumentsData() {
    let { documents } = this.state,
      { t } = this.props;
    let signed = documents.find(d => d.kind === 0);

    return documents.map(document => {
      return {
        className: '',
        data: [
          {
            className: '',
            value: (
              <a href={document.source} target={'_blank'}>
                {document.name}
              </a>
            )
          },
          { className: '', fixed: false, value: t(document.type) },
          {
            className: '',
            fixed: false,
            value:
              document.user && document.user[0]
                ? document.user[0].displayName
                : ''
          },
          {
            className: 'text-center',
            fixed: false,
            value: document.valide ? <i className="fa fa fa-check" /> : null
          },
          {
            className: 'text-center',
            fixed: false,
            value: document.send ? <i className="fa fa fa-check" /> : null
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: this.createOptions(document, signed)
          }
        ]
      };
    });
  }

  render() {
    let { loading, sendByEmail, docDelete, uploadDoc, projectId } = this.state,
      { t } = this.props;
    let rows = this.getDocumentsData();

    return (
      <div>
        <Card className={'card-plain'}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <Button
              size="sm"
              color={'info'}
              onClick={() => this.setState({ uploadDoc: true })}
            >
              <Trans>Add</Trans>
            </Button>
            <Button
              className={'ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>
          <CardBody>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[
                { value: t('Name') },
                { value: t('Type') },
                { value: t('User') },
                { value: t('Valid'), className: 'text-center' },
                { value: t('Sent'), className: 'text-center' },
                { value: '', className: 'text-right' }
              ]}
              rows={rows}
              emptyMessage={t('No documents found')}
            />
          </CardBody>
        </Card>

        {sendByEmail ? (
          <SendEmail
            hide={() => this.removeSendByEmail()}
            projectId={this.props.projectId}
            itemId={sendByEmail}
            itemType={'document'}
          />
        ) : null}

        {uploadDoc ? (
          <DocumentModal
            show={uploadDoc}
            projectId={projectId}
            onCancel={() => this.setState({ uploadDoc: false })}
            onConfirm={() =>
              this.setState({ uploadDoc: false }, () => this.load())
            }
          />
        ) : null}

        {docDelete ? (
          <AlertConfirm
            message={'The document cannot be recovered'}
            onCancel={() => this.setState({ docDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Documents));
