import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import {
  loadMaps,
  removeMap,
  saveMap
} from '../../../helpers/actions/projects';
import { AlertConfirm } from '../../../components';
import MapModal from './MapModal';

class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      maps: [],
      mapEdit: null,
      mapDelete: null
    };
  }

  componentWillMount() {
    let { maps } = this.props;
    if (!maps) this.load();
    else this.setState({ loading: false, ...this.props });
  }

  load() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;

    dispatch(loadMaps(projectId))
      .then(maps => {
        this.setState(ps => ({ ...ps, maps, loading: false }));
      })
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  saveMap(map) {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    dispatch(saveMap(projectId, map))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  remove() {
    this.setState({ loading: true });
    let { mapDelete } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removeMap(projectId, mapDelete._id))
      .then(() =>
        this.setState(ps => ({ ...ps, mapDelete: null }), () => this.load())
      )
      .catch(() =>
        this.setState(ps => ({ ...ps, mapDelete: null, loading: false }))
      );
  }

  render() {
    let { maps, mapEdit, mapDelete } = this.state;
    let { t } = this.props;

    return (
      <Row>
        <Col xs={6} style={{ textAlign: 'left' }}>
          <Trans>Models 3D</Trans>
        </Col>
        <Col xs={6} style={{ textAlign: 'right' }}>
          <Button
            size="sm"
            color={'info'}
            onClick={() => this.setState(ps => ({ ...ps, mapEdit: {} }))}
          >
            <Trans>Add</Trans>
          </Button>
          <Button
            className={'ml-2'}
            size="sm"
            color={'default'}
            onClick={() => this.load()}
          >
            <i className="now-ui-icons arrows-1_refresh-69" />
          </Button>
        </Col>
        {maps.map((m, i) => {
          let iframe = null;
          if (m.system === 'MagicPlan') {
            iframe = (
              <iframe
                title={`${t('3D Map MagicPlan')} ${i}`}
                src={'https://3d.magic-plan.com/#embed/?key=' + m.code}
                width="100%"
                height="600px"
                allowFullScreen={true}
                style={{ border: 0 }}
              />
            );
          }
          if (m.system === 'SketchUp') {
            iframe = (
              <iframe
                title={`${t('3D Map SketchUp')} ${i}`}
                src={'https://3dwarehouse.sketchup.com/embed/' + m.code}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                width="100%"
                height="600px"
                allowFullScreen={true}
              />
            );
          }
          return (
            <Col key={i} xs={12} md={6}>
              <Card className={this.props.class}>
                <CardHeader>
                  {m.title && m.system ? (
                    m.title + ' - ' + m.system
                  ) : (
                    <Trans>3D Model</Trans>
                  )}
                  <Button
                    color="danger"
                    size="sm"
                    className="ml-3 mt-0 pl-2 pr-2 float-right delete-map3d"
                    onClick={() => {
                      this.setState(ps => ({ ...ps, mapDelete: m }));
                    }}
                  >
                    <i className="fa fa-trash icon-action" />
                  </Button>
                </CardHeader>
                <hr />
                <CardBody>{iframe}</CardBody>
              </Card>
            </Col>
          );
        })}

        {!!mapEdit ? (
          <MapModal
            show={!!mapEdit}
            map={mapEdit}
            onCancel={() => this.setState(ps => ({ ...ps, mapEdit: null }))}
            onConfirm={map =>
              this.setState(
                ps => ({ ...ps, mapEdit: null }),
                () => this.saveMap(map)
              )
            }
          />
        ) : null}

        {mapDelete ? (
          <AlertConfirm
            message={'The map cannot be recovered'}
            onCancel={() => this.setState(ps => ({ ...ps, mapDelete: null }))}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </Row>
    );
  }
}

export default connect()(translate('translations-fr')(Maps));
