import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Spinner } from '../../../components';
import get from 'lodash/get';
import { addDocument } from '../../../helpers/actions/projects';

class MapModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: {},
      loading: false
    };
  }

  onChange(name, nameValid, value, valid) {
    let { document } = this.state;

    this.setState({
      document: { ...document, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  handleClick() {
    let input = document.getElementById('uploadDocument');
    input.click();
  }

  handleDocumentChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.onChange('file', 'fileValid', file, !!file);
  }

  validate() {
    let file = this.file;

    this.setState({
      [file.attributes.getNamedItem('namevalid').value]: file.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return file.validity.valid;
  }

  saveDocument() {
    if (this.validate()) {
      this.setState({ loading: true });
      let { document } = this.state,
        { dispatch, projectId } = this.props;

      document.type = 'Plans';

      dispatch(addDocument(projectId, [document.file], document.type))
        .then(() => {
          this.cleanModal();
          this.props.onConfirm();
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  cleanModal() {
    this.setState({
      typeValid: null,
      fileValid: null,
      document: {},
      loading: false
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  render() {
    let { document, loading } = this.state;

    return (
      <Modal
        size="md"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Upload Map</Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <FormGroup
                className={
                  'has-label form-validation-40 ' + this.state.fileValid
                }
              >
                <Label>
                  <Trans>Map</Trans>
                </Label>
                <Input
                  type="text"
                  innerRef={node => (this.file = node)}
                  namevalid="fileValid"
                  value={get(document.file, 'name', '')}
                  required="required"
                  enable={'false'}
                  onClick={() => this.handleClick()}
                />
                <Button
                  className="float-right"
                  size="sm"
                  color={'info'}
                  onClick={() => this.handleClick()}
                >
                  <Trans>Upload</Trans>
                </Button>
                <input
                  type="file"
                  id="uploadDocument"
                  name="uploadDocument"
                  accept="application/pdf, image/jpeg, image/png"
                  hidden={true}
                  multiple={false}
                  onChange={event => this.handleDocumentChange(event)}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.saveDocument()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(MapModal));
